import React, { useRef, Component, FC } from 'react'
import { NavBar, Avatar, Dialog, Image, Space, Button } from "antd-mobile"
import { getGetUserUserId, postGetTicketLogsByUserIdUserId } from '../../services/GourmetApi/Gourmet';
import "./MeCard.less"
import jh from "../../assets/img/card/jh.jpg"
import jh_active from "../../assets/img/card/jh_active.jpg"
const back = () => {
    (React.Component.prototype as any).history.goBack();
    (React.Component.prototype as any).showtar();
}
let that: MeCard;
export default class MeCard extends Component<any> {
    constructor(props: any) {
        super(props);
        (React.Component.prototype as any).hidetar();
        this.state = { user: { image: "", nickName: "" }, logs: [] };
        that = this;
    }
    state: Readonly<{ user: any, logs: Array<any> }>
    componentDidMount(): void {
        (React.Component.prototype as any).loading();
        const userId = localStorage.getItem("userId") || "";
        if (userId === "") {
            localStorage.setItem("goto", "/me");
            window.location.href = window.wx_user_info_url;
            return;
        } else {
            getGetUserUserId({ userId: userId }).then((ret: any) => {
             
                this.setState({ user: ret.data });
                postGetTicketLogsByUserIdUserId({ userId: userId }).then((logs: any) => {
                    this.setState({ logs: logs.data });
                    console.log(this.state);
                    console.log(logs);
                    (React.Component.prototype as any).closeLoading();
                });

            }).catch(() => {
                (React.Component.prototype as any).history.goBack();
            });
        }

    }
    wdl() {
        return <div>
            <div className="title">江湖卡未被点亮</div>
            <div className="cardImg"><Image src={jh}></Image></div>
            <div className='dlbtn' onClick={() => {
                (React.Component.prototype as any).history.push("/map");
                (React.Component.prototype as any).showtar();
            }}>您还可以投票{this.state.user.tickets}次，去投票</div>
        </div>
    }
    ydl() {
        return <div>
            <div className="title">江湖卡已被点亮</div>
            <div className="tips">恭喜您点亮了江湖卡，获得百事美食江湖活动抽奖资格，我们会在{window.kjrq}公布中奖结果</div>
            <div className="cardImg"><Image src={jh_active}></Image></div>
        </div>
    }
    render(): React.ReactNode {
        return <div className="mc" style={{ "height": "100%", "width": "100%" }}>
            <NavBar onBack={back} className="nav"> 我的点亮</NavBar>
            <div className="meCard">
                <div className='contet'>
                    <div style={{ "display": (this.state.user.id ? "block" : "none") }}>
                        {this.state.user.cardType === 1 ? this.ydl() : this.wdl()}
                        <div className="wdtp">
                            <div className='title'>
                                <div className="hg"></div>我的投票<div className="hg"></div>
                            </div>
                            <div className='top10'>
                                {this.state.logs.map((log, index) => {
                                    let item = log.shop;
                                    return <div key={index} className='top10item'>
                                        <div>
                                            <div className='act'>
                                                <Image className='img' src={item.images}></Image>
                                            </div>
                                            <div className='shopInfo'>
                                                <div className='name'>{item.name}</div>
                                                <div className='address'>{item.address}</div>
                                            </div>
                                        </div>
                                        <div className='rqz'>
                                            {item.chunTickets}
                                        </div>
                                    </div>
                                })}
                            </div>
                        </div></div>
                </div>

            </div></div>
    }
}