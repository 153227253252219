import React, { Component, FC } from 'react'
import { NavBar, TabBar, Avatar, List, ListItemProps, Swiper } from 'antd-mobile'
import { Button, Space, Grid, Image } from 'antd-mobile'
import {
  Route,
  Switch,
  Link,
  useHistory,
  useLocation,

  MemoryRouter as Router,
} from 'react-router-dom'
import {
  AppOutline,
  MessageOutline,
  UnorderedListOutline,
  UserOutline,
} from 'antd-mobile-icons'

import "./RegionShop.css"
import styles from './rs.less'
import ShopDetail from '../shop/ShopDetail'
const items: Array<any> = [];
for (let index = 0; index < 10; index++) {
  items.push(index)

}
let showShop: any = null;
const Op = (item: any) => {
  that.setState({ item: item });
  showShop = item
}
const imagesTouhStart = (e: any) => {
  e.stopPropagation();
  return false;
}
const imagesTouhMove = (e: any) => {
  e.stopPropagation();
  return false;
}

const imagesTouhEnd = (e: any) => {
  e.stopPropagation();
  return false;
}
const showShopDetail = (item: any) => {
  that.setState({ showDetail: item.id });
  showShop = item;
}
let that: any;
const ShopList = () => {

  return that.state.shops.map((item: any, index: number) => {
    return <div key={index} onClick={() => showShopDetail(item)} className={'mapItem mapItem_' + index}>
      <Image className='image-div' src={item.images.replace("/logo","/map/logo")}></Image>
      <div onClick={that.check} style={{ "display": that.state.showDetail == item.id ? "block" : "none" }} className="showName">
        <div>
          <Image src={item.images}></Image>
        </div>
        <div className='dtshop'>
          <div className='dtName'>{item.name}</div>
          <div className='dtrqz'>人气值:{item[that.state.item.code.toLocaleLowerCase() + "Tickets"] || 0}</div>
        </div>
        <div className="dtpm">
          <div>NO.{item.order}</div>
        </div>
      </div>
      <div>

      </div>
      <div>
      </div>
    </div>
  })
}
export default class RegionShop extends Component<any> {
  constructor(props: any) {
    super(props);
    this.state = { shops: this.props.shops, item: props.item, isShow: false, };
    that = this
  }
  state = {
    shops: Array,
    item: null,
    isShow: false,
  }
  check = () => {
    const isShow = this.state.isShow
    this.setState({
      isShow: !isShow
    })
    if (isShow) {
      if (this.props.reface) {
        this.props.reface();
      }
      (React.Component.prototype as any).showtar();
      (React.Component.prototype as any).showcity();

    } else {
      (React.Component.prototype as any).shopItem = showShop;
      (React.Component.prototype as any).city = that.state.item.code;

      (React.Component.prototype as any).history.push("/ShopDetail");
    }

  }

  componentDidMount() {
  }

  Show() {
    if (!that.state.item) {
      return
    } else {

      return <ShopDetail />
    }
  }
  render(): React.ReactNode {
    return <div className='regionShop'>
      <ShopList></ShopList>
      {/* {(!this.state.isShow) ? (<ShopList></ShopList>) : null} */}
      {/* {(this.state.isShow) ? (<ShopDetail city={that.state.item.code} style={{}} onback={this.check} item={showShop}></ShopDetail>) : null} */}
    </div>
  }
}