import { NavBar, Avatar, Dialog, Image, Space, Button } from "antd-mobile"
import {
    CloseCircleFill,

} from 'antd-mobile-icons'
import React, { Component, ReactNode } from "react"
import "./ShopDetail.less"
import { postTicker as postGourmetTicker } from "../../services/GourmetApi/Gourmet";
import jh from "../../assets/img/card/jh.jpg"
import jh_active from "../../assets/img/card/jh_active.jpg"
import Icon from "@ant-design/icons/lib/components/Icon";
let items: Array<any> = [];
for (let index = 0; index < 10; index++) {
    items.push(index)
}
const back = () => {
    (React.Component.prototype as any).history.push("/map");
    (React.Component.prototype as any).showtar();
    // (React.Component.prototype as any).showcity();
}
let _dialog: any;
const closeDialog = () => {
    if (_dialog) {
        _dialog.close();
        back()
    }

}
let user: any;
let ticket = async (city: string) => {
    (React.Component.prototype as any).loading();
    if (!localStorage.getItem("userId")) {
        localStorage.setItem("goto", "/ShopDetail");
        localStorage.setItem("ShopDetail", JSON.stringify(item));
        localStorage.setItem("city", city);
        window.location.href = window.wx_user_info_url;
        // var user: any = await getGourmetGetUserUserId({ userId: "admin" });
        // localStorage.setItem("userId", user.data.userId)
        return;
    }
    let ret: any = await postGourmetTicker({
        city: city,
        shopId: item.id,
        userId: localStorage.getItem("userId") as string
    });
    user = ret.data;
    (React.Component.prototype as any).closeLoading();
    if (ret.data.cardType == "1") {
        _dialog = Dialog.show({
            image: "",
            title: '',
            actions: [],
            content: (<>
                <DailContent></DailContent>
            </>),
        })
    } else {
        _dialog = Dialog.show({
            image: "",
            title: '',
            actions: [],
            content: (<>
                <FaildailContent></FaildailContent>
            </>),
        })
    }
}
const DailContent = () => {
    return <div className="dialog">
        <div className="close" onClick={closeDialog}><CloseCircleFill /></div>
        <div className="title">成功点亮江湖卡</div>
        <div className="tips">
            <div >恭喜你点亮了江湖卡，获得百事美食江湖活动抽奖资格，我们会在{window.kjrq}公布中奖结果</div>
            <Image className="cardimg" src={jh_active}></Image>
            <div className="tpts" onClick={() => { _dialog.close(); (React.Component.prototype as any).history.push("/me"); (React.Component.prototype as any).showtar(); }}> 去个人中心查看{"我的点亮"}<div className="opjt">{">"}</div></div>
        </div>
    </div>
}
const FaildailContent = () => {
    return <div className="dialog">
        <div className="close" onClick={closeDialog}><CloseCircleFill /></div>
        <div className="title"> 很遗憾</div>
        <div className="tips">
            <div>您的运气差了一丢丢，未能点亮江湖卡，您可以继续投票，有更大的概率点亮江湖卡！</div>
            <Image className="cardimg" src={jh}></Image>
            <div className="tpts" onClick={closeDialog}> 您还可以投票{user.tickets}次,继续投票<div className="opjt">{">"}</div></div>
        </div>

    </div>
}
let that: ShopDetail;
let item: any;
let city: string;
export default class ShopDetail extends Component<any> {
    constructor(props: any) {

        super(props);
        item = (React.Component.prototype as any).shopItem;
        city = (React.Component.prototype as any).city;
        (React.Component.prototype as any).hidetar();
        // (React.Component.prototype as any).hidecity();
        that = this;
    }

    render(): ReactNode {
        return <div className="sdxqy" style={{ "height": "100%", "width": "100%" }}>
            <NavBar onBack={back} className="nav"> 门店详情</NavBar>
            <div className="shopcontent">
                <div className="detail">
                    <Image className="shopImg" src={item.images}></Image>
                    <div className="info">
                        <div className="shop">
                            <div className='header'>
                                <div>
                                    <div className=''>
                                        <div>百事美食江湖 No.{item.order}</div>
                                    </div>
                                </div>
                                <div className="rqz">人气值：{item[city.toLocaleLowerCase() + "Tickets"] || 0}</div>
                            </div>
                            <div className='shopInfo'>
                                <div>{item.name}</div>
                                <div className="address">{item.address}</div>
                            </div>
                            <div className='openTime'>
                                <div>营业时间:{item.openTime || "8:00~18:00"}</div>
                            </div>
                        </div>
                        <div className='setmeat'
                        >
                            <div className="tjms">推荐美食</div>
                            {item.setmeals.map((setmeal: any, index: number) => {
                                return <div key={index} className="setmeal">
                                    <div className="setmealInfo"><Image
                                        src={setmeal.image}
                                        fit='cover'
                                        className="pic"
                                        style={{ borderRadius: 4 }}
                                    />
                                        <div className="shopdec">
                                            <div className="dec1">{setmeal.name}</div>
                                            <div className="dec2">{setmeal.dec}</div>
                                        </div>
                                    </div>
                                    <div className="price">{setmeal.price ? <div>{setmeal.price / 100}￥</div> : <div></div>}</div>
                                </div>
                            })}
                        </div>
                        <div className="yhq">
                            <div>大众点评优惠券</div>
                            <div>{item.discounts}张可用</div>
                        </div>
                    </div>

                </div>
            </div>
            {
                !item.isTicket ? <div className="detailopbtn" >
                    <Button onClick={() => ticket(city)} > 立即投票</Button>
                </div> : <div className="detailopbtn" >
                    <Button  > 已投票</Button>
                    {/* <Button onClick={() => ticket(city)} > 立即投票</Button> */}
                </div>
            }

        </div >
    }
}