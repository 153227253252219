import React, { useRef, Component, FC } from 'react'
import { Button, Space, Grid, Image, } from 'antd-mobile'
import "./Me.less"
import { RightOutline } from 'antd-mobile-icons';
import { getGetUserUserId } from '../../services/GourmetApi/Gourmet';
const logout = () => {
    localStorage.removeItem("userId");
    window.location.href = "/";
}
export default class Me extends Component<any> {
    constructor(props: any) {
        super(props);
        (React.Component.prototype as any).showtar();
        this.state = { user: { image: "", nickName: "" } };
    }
    state: Readonly<{ user: { image: string, nickName: string } }>
    componentDidMount(): void {
        (React.Component.prototype as any).loading();
        const userId = localStorage.getItem("userId") || "";
        if (userId === "") {
            localStorage.setItem("goto", "/me");
            window.location.href = window.wx_user_info_url;
            return;
        } else {
            getGetUserUserId({ userId: userId }).then((ret: any) => {
                this.setState({ user: ret.data });
                (React.Component.prototype as any).closeLoading();
            }).catch(() => {
                (React.Component.prototype as any).history.goBack();
            });

        }

    }
    render(): React.ReactNode {
        return <div className="mecontent"><div className='me'>
            <div className='user'>
                <Image className='userImg' src={this.state.user.image}></Image>
                <div className='nick'>{this.state.user.nickName}</div>
            </div>
            <div className='prize'>
                <div className='prize-item medl' onClick={() => (React.Component.prototype as any).history.push("/meCard/")}>

                </div>
                <div className='prize-item wdjp' onClick={() => (React.Component.prototype as any).history.push("/mePrize/")}>
                
                </div> 
                <div className='prize-item tc' onClick={logout}>
                  
                </div>
            </div>
        </div>
        </div>
    }
}