import React, { useRef, useState, Component, FC } from 'react'
import { Form, Input, NavBar, Popup, Toast, CascaderView, Cascader, Avatar, Dialog, Image, Space, Button } from "antd-mobile"
import { getGetUserUserId, postGetIsPrizeList, postComfirmInfo } from '../../services/GourmetApi/Gourmet';
import "./Prize.less"
import jh from "../../assets/img/card/jh.jpg"
import jh_active from "../../assets/img/card/jh_active.jpg"
import regions from "../../assets/region.json"
const back = () => {
    (React.Component.prototype as any).history.push("/me");
    (React.Component.prototype as any).showtar();
}
let that: Prize;
let regionData: any;
const onFinish = (data: any, cl: Function) => {
    console.log("shuju ", data);
    if( 'region' in data) {
        if (!regionData || regionData.length == 0) {
            Dialog.alert({

                content: '请选择省市区',
                onConfirm: () => {
                },
            });
            return;
        }
        data.region = regionData.join("");
    }

    data.userId = that.state.user.userId;
    cl();
    Dialog.confirm({
        content: '是否提交信息',
        bodyClassName: "tjdialogbody",
        onConfirm: async () => {
            (React.Component.prototype as any).loading();
            await postComfirmInfo(data);
            that.componentDidMount();
            // (React.Component.prototype as any).history.push("/mePrize");

        },
    })

}
const Opbtn: React.FunctionComponent = () => {
    const [visible1, setVisible1] = useState<boolean>(false)
    const [visible2, setVisible2] = useState<boolean>(false)
    const [visible, setVisible] = useState<boolean>(false)
    const [region, setRegione] = useState<string[]>([])
    return <div className="jpan">
        <div onClick={() => {
            setVisible1(true)
        }}>邮寄给我</div>
        <div onClick={() => {
            setVisible2(true)
        }}>联系我们线下领奖</div>
        <Popup

            visible={visible1}
            onMaskClick={() => {
                setVisible1(false)
            }}
            bodyStyle={{ height: '1051px' }}
        >
            <div onClick={() => setVisible1(false)} className="popclose">X</div>
            <Form
                name='form'
                requiredMarkStyle='none'
                className="yj_form"
                onFinish={(data) => { onFinish(data, () => { setVisible1(false) }); }}
                footer={
                    <Button block type='submit' color='primary' size='large'>
                        立即提交
                    </Button>
                }
            >
                <Form.Header ><div className='yj_title'>填写收货地址，我们会在{window.yjffrq}发放给您</div></Form.Header>
                <Form.Item name='userName' label='收件人' rules={[{ required: true }]}>
                    <Input placeholder='' />
                </Form.Item>
                <Form.Item name='phone' label='联系方式' rules={[{ required: true }]}>
                    <Input placeholder='' />
                </Form.Item>
                <Form.Item name='region' label='地区' rules={[{ required: false }]}>
                    {/* <div className="regionTitle"> */}
                    <Input onClick={() => {
                        setVisible(true)
                    }} name='region' value={region.join("")} placeholder='' />
                    <Cascader
                        onConfirm={(val) => { setRegione(val); regionData = val; }}
                        options={regions.regions}
                        visible={visible}
                        onClose={() => {
                            setVisible(false)
                        }}
                    />
                </Form.Item>
                <Form.Item name='address' label='请填写详细地址' rules={[{ required: true }]} >
                    <Input placeholder='' />
                </Form.Item>



            </Form>
        </Popup>
        <Popup

            visible={visible2}
            onMaskClick={() => {
                setVisible2(false)
            }}
            bodyStyle={{ height: '697px' }}
        >
            <div onClick={() => setVisible2(false)} className="popclose">X</div>
            <Form
                name='form'
                requiredMarkStyle='none'
                className="yj_form"
                onFinish={(data) => { onFinish(data, () => { setVisible1(false) }); }}
                footer={
                    <Button block type='submit' color='primary' size='large'>
                        立即提交
                    </Button>
                }
            >
                <Form.Header ><div className='yj_title'>填写您的联系方式，我们的工作人员会在{window.yjffrq} 致电联系您</div></Form.Header>
                <Form.Item name='userName' label='称呼' rules={[{ required: true }]}>
                    <Input placeholder='' />
                </Form.Item>
                <Form.Item name='phone' label='联系方式' rules={[{ required: true }]}>
                    <Input placeholder='' />
                </Form.Item>
            </Form>
        </Popup>
    </div>;
}
export default class Prize extends Component<any> {

    constructor(props: any) {
        super(props);
        (React.Component.prototype as any).hidetar();
        this.state = { user: { image: "", nickName: "" }, logs: [] };
        that = this;
        // options=window.region
    }

    state: Readonly<{ user: any, logs: Array<any> }>
    componentDidMount(): void {
        (React.Component.prototype as any).loading();
        const userId = localStorage.getItem("userId") || "";
        if (userId === "") {
            localStorage.setItem("goto", "/me");
            window.location.href = window.wx_user_info_url;
            return;
        } else {
            getGetUserUserId({ userId: userId }).then((ret: any) => {
                this.setState({ user: ret.data });
                postGetIsPrizeList().then((logs: any) => {
                    let copy_logs=[...logs.data ];
                    this.setState({ logs: copy_logs });
                    console.log(this.state);
                    console.log(logs);
                    (React.Component.prototype as any).closeLoading();
                });

            }).catch(() => {
                (React.Component.prototype as any).history.goBack();
            });

        }

    }
    wdl() {
        return <div>
            <div className="wdljptips">很遗憾，您暂未获得抽奖名额<br />请您投票点亮江湖卡</div>
            <div className='opbtn' onClick={() => { (React.Component.prototype as any).history.push("/map"); (React.Component.prototype as any).showtar(); }}>您还可以投票{this.state.user.tickets}次,去投票</div>
        </div >
    }
    ydl() {
        return <div>
            <div className="jptips">恭喜您已获得抽奖名额我们会在{window.kjrq}开奖</div>
        </div>
    }
    wzj() {
        return <>
            <div className="wzjtips">
                很遗憾，您未中奖
                {this.zjmd()}
            </div>
        </>
    }
    yzj() {

        return <>
            <div className="yzjtips">
                <div className="title">恭喜您获得{this.state.user.prize.name}</div>
                <div className='jpzp'> <Image src={this.state.user.prize.image}></Image></div>
                <div className='jpmc'>{this.state.user.prize.description}*1</div>
                <div>  {!this.state.user.userName || this.state.user.userName == "" ? <Opbtn /> : this.userInfo()}</div>
                {this.zjmd()}
            </div>
        </>

    }
    zjmd(){
        return <>
        <div className="hjmd">
                    <div className="zjnr">
                        <div className='title'>
                            <div className="hg"></div>获奖名单<div className="hg"></div>


                        </div>
                        <div className="zjmd">

                            {this.state.logs.map((item, index) => {
                                return <div key={index}>
                                    <div>{item.nickName}</div>
                                    <div>{item.prize.name}&nbsp;&nbsp;&nbsp;&nbsp;{item.prize.description}</div>
                                </div>
                            })}

                        </div>
                    </div>
                </div> </>
    }
    yj() {
        return <Form
            name='form'
            className="yj_form"
            // onFinish={onFinish}
            footer={
                <Button block type='submit' color='primary' size='large'>
                    提交
                </Button>
            }
        >
            <Form.Header ><div className='yj_title'>填写收货地址，我们会在XX个工作日发放给您</div></Form.Header>
            <Form.Item name='name' label='收件人' rules={[{ required: true }]}>
                <Input placeholder='请输入姓名' />
            </Form.Item>
            <Form.Item name='phone' label='联系方式' rules={[{ required: true }]}>
                <Input placeholder='请输入联系方式' />
            </Form.Item>
            <Form.Item name='region' label='地区' rules={[{ required: true }]}>
                {/* <Cascader
                    options={options}
                    visible={visible}
                    onClose={() => {
                        setVisible(false)
                    }}
                /> */}
            </Form.Item>
            <Form.Item name='address' label='请填写详细地址' >
                <Input placeholder='请输入地址' />
            </Form.Item>



        </Form>
    }
    userInfo() {
        return <div className="zjyjxxxs">
            <div className="title">{!this.state.user.region ? "线下联系信息" : "邮寄信息"}</div>
            <div>{!this.state.user.region ? "称呼" : "收件人"}：{this.state.user.userName}</div>
            <div>联系方式：{this.state.user.phone}</div>
            {this.state.user.region ? <div>地址：{this.state.user.region}{this.state.user.address}</div> : <div></div>}

        </div>;
    }
    render(): React.ReactNode {
        return <div className="prize" style={{ "height": "100%", "width": "100%" }}>
            <NavBar onBack={back} className="nav"> 我的奖品</NavBar>
            <div className='contet'>
                <div style={{ "display": (this.state.user.id ? "block" : "none") }}>
                    {this.state.user.prizeStatus}-{new Date()<window.kjdate}
                    {(!this.state.user.prizeStatus|| new Date()<window.kjdate) ? (this.state.user.cardType === 1 ? this.ydl() : this.wdl()) : (this.state.user.prizeStatus == 1 ? this.wzj() : this.yzj())}
                </div>
            </div></div>
    }
}