import React, { useRef, Component, FC } from 'react'
import { Button, Space, Grid, Image } from 'antd-mobile'
import { postGetShopList as postGourmetGetShopList, postGetShopOrder } from '../../services/GourmetApi/Gourmet'
import "./Bangdan.less"
export default class Bangdan extends Component<any> {
    constructor(props: any) {
        super(props)
        this.state = { items: [] }
        this.initData()
    }
    async initData() {
        (this as any).loading()
        var ret: any = await postGetShopOrder({});
        ret.data = (ret.data as []);
        this.setState({ items: [...ret.data] });
        (this as any).closeLoading();
    }
    state: Readonly<{ items: Array<any> }>
    render(): React.ReactNode {
        return <div className='bdcontent'><div className='bd'><div className='bangdan'>
            <div className='title'></div>
            {/* <div style={{fontSize:"36px"}}>敬请期待</div> */}
            <div className='top3'>
                <div className='two'>
                    <div>
                        {this.state.items.length > 1 ? <div className='item'>
                            <div>
                                {(this.state.items[1] as any).name}
                            </div>
                            <div className='rqz'>
                                {(this.state.items[1] as any).chunTickets}
                            </div>
                            <div className='address'>
                                {(this.state.items[1] as any).address}
                            </div>
                        </div>
                            : <div></div>}

                    </div>
                </div>
                <div className='one'>
                    {this.state.items.length > 0 ? <div className='item'>
                        <div>
                            {(this.state.items[0] as any).name}
                        </div>
                        <div className='rqz'>
                            {(this.state.items[0] as any).chunTickets}
                        </div>
                        <div className='address'>
                            {(this.state.items[0] as any).address}
                        </div>
                    </div> : <div></div>}
                </div>
                <div className='three'>
                    {this.state.items.length > 2 ? <div className='item'>
                        <div>
                            {(this.state.items[2] as any).name}
                        </div>
                        <div className='rqz'>
                            {((this.state.items[2] as any).chunTickets ? (this.state.items[2] as any).chunTickets : 0)}
                        </div>
                        <div className='address'>
                            {(this.state.items[2] as any).address}
                        </div>
                    </div> : <div></div>}
                </div>
            </div>
            <div className='top10'>
                {this.state.items.map((item: any, index) => {

                    if (index > 2 && index < 20) {
                        return <div key={index} className='top10item'>
                            <div>
                                <div className='pm'>{String(index + 1).padStart(2, '0')}</div>
                                <div className='act'>
                                    <Image className='img' src={item.images}></Image>
                                </div>
                                <div className='shopInfo'>
                                    <div className='name'>{item.name}</div>
                                    <div className='address'>{item.address}</div>
                                </div>
                            </div>
                            <div className='rqz'>
                                {item.chunTickets}
                            </div>
                        </div>
                    }
                })}
            </div>
        </div >
        </div>
        </div>
    }
}