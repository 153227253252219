import React, { FC, Component } from 'react';
import { NavBar, TabBar } from 'antd-mobile/2x';
import { Image, Space } from 'antd-mobile';
//@ts-ignore 
import Xgplayer from 'xgplayer-react';
import {
  Route,
  Switch,
  useHistory,
  useLocation,
  MemoryRouter as Router,
} from 'react-router-dom'
import {
  AppOutline,
  MessageOutline,
  UnorderedListOutline,
  HistogramOutline,
  EnvironmentOutline,
  RightOutline,
  UserOutline,

} from 'antd-mobile-icons'
import { createFromIconfontCN } from '@ant-design/icons';
import Ticket from "./ticket/Ticket"
import './MainIndex.less'
import ShopDetail from './shop/ShopDetail'
import homeImg from "../assets/img/home/main_home.png"
import Bangdan from './phb/Bangdan'
import { getGetUserByCodeCode } from '../services/GourmetApi/Gourmet'
import MeCard from './me/MeCard'
import Prize from './me/Prize'
import Me from './me/Me';
import syspfm from "../assets/img/home/syspfm.jpg"
let setRouteActive: any
let repacleRouteActive: any

const getUrlParam = (name: string) => {
  return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(window.location.href) || [, ""])[1].replace(/\+/g, '%20')) || null
}
let isHide;
(React.Component.prototype as any).hidetar = () => {

}
let config = {
  id: 'vs1',
  url: '/xuanchuan.mp4',
  lang: 'zh-cn',
  poster: syspfm
};
let Player: any = null;
const Bottom: FC = () => {
  const history = useHistory()
  const location = useLocation()
  const { pathname } = location
  const IconFont = createFromIconfontCN({
    scriptUrl: '//at.alicdn.com/t/c/font_3991761_pg0q0sjfb2.js',
  });
  setRouteActive = (value: string) => {
    history.push(value);
    window.scrollTo(0, 0);
  }
  repacleRouteActive = (value: any) => {
    history.push({ pathname: "/ShopDetail", component: ShopDetail, props: { item: {} }, state: { info: "你好，我是info" } })
  }
  (React.Component.prototype as any).history = history;
  const tabs = [
    {
      key: '/home',
      title: '首页',
    },
    {
      key: '/map',
      title: '江湖',
    },
    // {
    //   key: '/todo',
    //   title: '',
    //   icon: <EnvironmentOutline />,
    // },
    {
      key: '/message',
      title: '榜单',
    },
    {
      key: '/me',
      title: '我的',
    },
  ]

  return (
    <TabBar activeKey={pathname} onChange={value => setRouteActive(value)}>
      {tabs.map((item, index) => (
        <TabBar.Item className={'bottom-btn bottom-btn_' + index} key={item.key} title={item.title} />
      ))}
    </TabBar>
  )
}
let that: MainIndex;
export default class MainIndex extends Component<any> {
  constructor(props: any) {
    super(props);
    this.state = { items: [], home: false, isHideBottom: false };
    that = this;
    (React.Component.prototype as any).hidetar = () => {
      this.setState({ isHideBottom: true })
    }
    (React.Component.prototype as any).showtar = () => {
      this.setState({ isHideBottom: false })
    }
  }
  componentDidMount(): void {
    var code = getUrlParam("code");
    if (code) {
      localStorage.setItem("canGoto", "1");
      (React.Component.prototype as any).loading();
      getGetUserByCodeCode({ code: code }).then((ret: any) => {
        localStorage.setItem("userId", ret.data.userId);
        window.location.href = "/";
        console.log(ret);
      }).catch(e => {
        window.location.href = "/";
      }).finally(() => {
        (React.Component.prototype as any).closeLoading();
      });

    } else {
      const canGo = localStorage.getItem("canGoto") || "";
      if ("1" === canGo) {
        localStorage.removeItem("canGoto");
        const goto = localStorage.getItem("goto") || "";
        if (goto && goto !== "") {
          (React.Component.prototype as any).loading();
          setTimeout(() => {
            localStorage.removeItem("goto");
            const shopItem = localStorage.getItem("ShopDetail") || "{}";
            (React.Component.prototype as any).shopItem = JSON.parse(shopItem);
            localStorage.removeItem("ShopDetail");
            (React.Component.prototype as any).city = localStorage.getItem("city") || "";
            (React.Component.prototype as any).closeLoading();
            (React.Component.prototype as any).history.push(goto);

          }, 800);
        }
      }
    }
  }
  state: Readonly<{ items: [], home: false, isHideBottom: false }>
  render(): React.ReactNode {
    return (
      <Router initialEntries={['/home']}>
        <div className="app">
          {/* <div className="top">
                <NavBar>配合路由使用</NavBar>
              </div> */}
          <div className="body">
            <Switch>
              <Route path='/home'>
                <Home />
              </Route>
              <Route path='/todo'>
                <Todo />
              </Route>
              <Route path='/map'>
                <Todo />
              </Route>
              <Route path='/message'>
                <Message />
              </Route>
              <Route path='/me'>
                <PersonalCenter />
              </Route>
              <Route path='/meCard'>
                <MeCard />
              </Route>
              <Route path='/mePrize'>
                <Prize />
              </Route>
              <Route path='/ShopDetail'>
                <ShopDetail />
              </Route>
            </Switch>
          </div>
          <div className='bottom' style={{ display: this.state.isHideBottom ? "none" : "block" }}>
            <Bottom />
          </div>
        </div>
      </Router>
    )
  }
}
// export default () => {
//   return (
//     <Router initialEntries={['/home']}>
//       <div className="app">
//         {/* <div className="top">
//           <NavBar>配合路由使用</NavBar>
//         </div> */}
//         <div className="body">
//           <Switch>
//             <Route path='/home'>
//               <Home />
//             </Route>
//             <Route path='/todo'>
//               <Todo />
//             </Route>
//             <Route path='/message'>
//               <Message />
//             </Route>
//             <Route path='/me'>
//               <PersonalCenter />
//             </Route>
//           </Switch>
//         </div>
//         <div className='bottom'>
//           <Bottom />
//         </div>
//       </div>
//     </Router>
//   )
// }

function Home() {
  return <div className='home'>
    <div className='hjtztips'>
      <div>
        获奖信息{window.kjrq}公布，敬请期待~
      </div>
      <div onClick={() => setRouteActive('/map')}>去投票赢抽奖资格 <div className='yjtfh'><RightOutline /></div></div>
    </div>
    <div className='jplx'>
      <div className='animate'>

       {window.bs_prizes.map((item,index)=>{
          return <div className='bs_prize' key={index}>
            <div>{item.name}</div>
            <div>{item.desc}</div>
            <div>{item.num}</div>
          </div>
       })}
      </div>
    </div>
    {/* <Image style={{ display: that.state.home ? "none" : '' }} src={homeImg}></Image> */}
    <div className='videoplay' id="vs1">
      <Xgplayer
        config={config}
        playerInit={(player: any) => {
          Player = player;
        }}
      />
    </div>
  </div>
}

function Todo() {
  return <Ticket changroute={repacleRouteActive}></Ticket>
}

function Message() {
  return <Bangdan />
}

function PersonalCenter() {
  return <Me></Me>
}