import React, { useRef, Component, FC } from 'react'
import { Space, Swiper, SwiperRef, DotLoading, Tabs, Image } from 'antd-mobile'
import { getAllEnumItem } from '../../Global'
import { postGetShopList as postGourmetGetShopList } from "../../services/GourmetApi/Gourmet"
import {
  Route,
  Switch,
  useHistory,
  useLocation,
  MemoryRouter as Router,
} from 'react-router-dom'
import {
  AppOutline,
  MessageOutline,
  UnorderedListOutline,
  UserOutline,
  LeftOutline,
  RightOutline
} from 'antd-mobile-icons'

import RegionShop from './RegionShop'
import "./Ticket.less"
import mapImg from "../../assets/img/Map/map.png"
let items: Array<any> = [];



let that: Tickets;
const InitSW = () => {
  const ref = useRef<SwiperRef>(null);
  let tips = ["春", "夏", "秋", "冬"]
  return <Swiper className='map-shops' ref={ref} indicator={(total, current) => (
    <div style={{ display: that.state.hidecity ? "none" : "none" }}> {current > 0 ? <div className='tip_left'  > <div onClick={() => { ref.current?.swipePrev() }}> {`${tips[current - 1]}`}
      <RightOutline ></RightOutline ></div></div> : <div></div>}
      {current < 3 ? <div className="tip_right" >
        <div onClick={() => { debugger; ref.current?.swipeNext() }}><LeftOutline />{`${tips[current + 1]}`}
        </div> </div> : <div></div>}

    </div>
  )}>
    {/* <Swiper.Item className='map-shops'>
      <div className='mapItem mapItem_1'>
        <Image src={(that.state.datas[0]).images}></Image>
      </div>
      <div className='mapItem mapItem_2'>
        2
      </div>
      <div className='mapItem mapItem_3'>
        3
      </div>
      <div className='mapItem mapItem_4'>
        4
      </div>
      <div className='mapItem mapItem_5'>
        5
      </div>
      <div className='mapItem mapItem_6'>
        6
      </div>
      <div className='mapItem mapItem_7'>
        7
      </div>
    </Swiper.Item> */}

    {that.state.items}
  </Swiper >
}
export default class Tickets extends Component<any> {
  constructor(props: any) {
    super(props);
    console.log(props)
    this.state = { items: [], datas: [{ images: "" }], hidecity: false };
    that = this;
    (React.Component.prototype as any).hidecity = () => {
      this.setState({ hidecity: true })
    }
    (React.Component.prototype as any).showcity = () => {
      this.setState({ hidecity: false })
    }
  }
  reface() {
    that.initIetms();
  }
  readonly state: Readonly<{ items: [], datas: [{ images: "" }], hidecity: false }>
  initIetms = async () => {
    (this as any).loading();
    // return;
    // const enums: any = await postGourmetGetAllEnumItem();
    const shops: any = await postGourmetGetShopList({ userId: localStorage.getItem("userId") as string });
    // const data: Array<any> = (enums.data as []).map((item: any, index) => {
    //   return <Swiper.Item key={new Date().getTime() + item.id}>
    //     <RegionShop className="regionShop" reface={this.reface} item={item} shops={shops.data}></RegionShop>
    //   </Swiper.Item >;
    // }) as [];
    const item = {
      id: "2222", code: "Chun", name: "春"
    };
    console.log(this.state)
    const data = [(<Swiper.Item className='map-shops' key={new Date().getTime() + item.id}>


      <RegionShop changroute={this.props.changroute} className="regionShop" reface={this.reface} item={item} shops={shops.data}></RegionShop>
    </Swiper.Item >)]
    this.setState({
      items: data,
      datas: shops.data
    });
    (this as any).closeLoading();

  }
  componentDidMount(): void {
    this.initIetms();
  }
  render(): React.ReactNode {
    return (
      <div className='content'>
        <div className='mapbg'>
          {/* <Image className='bgimg' src={mapImg}></Image> */}
          <InitSW></InitSW>
        </div>
      </div >
    )
  }
}


