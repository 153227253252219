// @ts-ignore
/* eslint-disable */
import request from '../../common/Request';

/** 此处后端没有提供注释 POST /api/gourmet/comfirmInfo */
export async function postComfirmInfo(body: API.ComfirmInfo, options?: { [key: string]: any }) {
  return request<any>('/api/gourmet/comfirmInfo', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 此处后端没有提供注释 POST /api/gourmet/comfirmPrizeUser */
export async function postComfirmPrizeUser(body: string[], options?: { [key: string]: any }) {
  return request<any>('/api/gourmet/comfirmPrizeUser', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 此处后端没有提供注释 POST /api/gourmet/getAllEnumItem */
export async function postGetAllEnumItem(options?: { [key: string]: any }) {
  return request<API.EnumItemListModelResponse>('/api/gourmet/getAllEnumItem', {
    method: 'POST',
    ...(options || {}),
  });
}

/** 此处后端没有提供注释 GET /api/gourmet/getConfig */
export async function getGetConfig(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getGetConfigParams,
  options?: { [key: string]: any },
) {
  return request<API.WeiXinConfigModelResponse>('/api/gourmet/getConfig', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 此处后端没有提供注释 POST /api/gourmet/getEnumItemByModule */
export async function postGetEnumItemByModule(body: string, options?: { [key: string]: any }) {
  return request<API.EnumItemListModelResponse>('/api/gourmet/getEnumItemByModule', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 此处后端没有提供注释 POST /api/gourmet/getIsPrizeList */
export async function postGetIsPrizeList(options?: { [key: string]: any }) {
  return request<API.UserPrizePagedListResponse>('/api/gourmet/getIsPrizeList', {
    method: 'POST',
    ...(options || {}),
  });
}

/** 此处后端没有提供注释 POST /api/gourmet/getShopList */
export async function postGetShopList(
  body: API.GetShopListInput,
  options?: { [key: string]: any },
) {
  return request<API.ShopModelPagedListResponse>('/api/gourmet/getShopList', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 此处后端没有提供注释 POST /api/gourmet/getShopOrder */
export async function postGetShopOrder(
  body: API.GetShopListInput,
  options?: { [key: string]: any },
) {
  return request<API.ShopModelPagedListResponse>('/api/gourmet/getShopOrder', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 此处后端没有提供注释 POST /api/gourmet/getTicketLogsByUserId/${param0} */
export async function postGetTicketLogsByUserIdUserId(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.postGetTicketLogsByUserIdUserIdParams,
  options?: { [key: string]: any },
) {
  const { userId: param0, ...queryParams } = params;
  return request<API.TicketLogPagedListResponse>(`/api/gourmet/getTicketLogsByUserId/${param0}`, {
    method: 'POST',
    params: { ...queryParams },
    ...(options || {}),
  });
}

/** 此处后端没有提供注释 GET /api/gourmet/getUser/${param0} */
export async function getGetUserUserId(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getGetUserUserIdParams,
  options?: { [key: string]: any },
) {
  const { userId: param0, ...queryParams } = params;
  return request<API.UserPrizeModelResponse>(`/api/gourmet/getUser/${param0}`, {
    method: 'GET',
    params: { ...queryParams },
    ...(options || {}),
  });
}

/** 此处后端没有提供注释 GET /api/gourmet/getUserByCode/${param0} */
export async function getGetUserByCodeCode(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getGetUserByCodeCodeParams,
  options?: { [key: string]: any },
) {
  const { code: param0, ...queryParams } = params;
  return request<API.UserPrizeModelResponse>(`/api/gourmet/getUserByCode/${param0}`, {
    method: 'GET',
    params: { ...queryParams },
    ...(options || {}),
  });
}

/** 此处后端没有提供注释 POST /api/gourmet/ticker */
export async function postTicker(body: API.TickerInput, options?: { [key: string]: any }) {
  return request<API.UserPrizeModelResponse>('/api/gourmet/ticker', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}
