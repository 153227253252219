import React from 'react';
import './App.css';
import MainIndex from './pages/MainIndex';
import { DotLoading, SpinLoading } from 'antd-mobile'
//@ts-ignore 
import wx from 'weixin-js-sdk'
import { getGetConfig } from './services/GourmetApi/Gourmet'
const intWxConfig = () => {

  let url=window.location.href;
  if(url.indexOf("?")>-1){
    url=url.substring(0,url.indexOf("?"));
  }
  getGetConfig({ url: url}).then((ret: any) => {
    ret.data.jsApiList=['onMenuShareAppMessage','updateAppMessageShareData','updateTimelineShareData','onMenuShareTimeline'];
    wx.config(ret.data);
    wx.error(function(res:any){
      console.log(res);
      // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
    });
    
    wx.ready(() => {
     let option ={
      title: '百事美食江湖', // 分享标题
      desc: '寻味百事烟火，记录江湖人情\r\n我们江湖见......', // 分享描述
      link: url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
      imgUrl: url+'/logo/logo.jpg', // 分享图标
      type: '', // 分享类型,music、video或link，不填默认为link
      dataUrl: '', // 如果type是music或video，则要提供数据链接，默认为空
      success: function () {
        // 用户确认分享后执行的回调函数
      },
      cancel: function () {
        // 用户取消分享后执行的回调函数
      }
    };
      //分享给朋友
      wx.updateAppMessageShareData(option)
          wx.onMenuShareAppMessage(option);
         
          //分享到朋友圈
          wx.onMenuShareTimeline(option);
		  wx.updateTimelineShareData(option);
    });
  });
};
const initViewport = () => {
  const width = 750;  // 设计稿宽度
  const scale = window.innerWidth / width
  // console.log('scale', scale)
  let meta = document.querySelector('meta[name=viewport]')
  let content = `width=${width}, init-scale=${scale}, user-scalable=no`
  if (!meta) {
    meta = document.createElement('meta')
    meta.setAttribute('name', 'viewport')
    document.head.appendChild(meta)
  }
  meta.setAttribute('content', content)
}
export default class TApp extends React.Component {
  constructor(props: any) {
    initViewport();
    try {
      intWxConfig();
    } catch (e) {
      console.error(e);
    }

    super(props);
    this.state = { isLoding: false };
    (React.Component.prototype as any).loading = () => {
      this.setState({ isLoding: true })
    }
    (React.Component.prototype as any).closeLoading = () => {
      this.setState({ isLoding: false })
    }
  }
  state: Readonly<{ isLoding: false }>;
  render(): React.ReactNode {
    return <div>
      {/* <div className='loading'> <SpinLoading /></div> */}
      {this.state.isLoding ? <div className='loading'> <SpinLoading /></div> : null}
      <MainIndex></MainIndex></div>

  }
}
